.page-header{
  display: flex;
  flex-direction: column;
  background-color: var(--color-page-header);
}

.page-header .header-content{
  margin: 0 auto;
  position: relative;
  margin: 3.2rem auto;
  align-content: center;
  align-items: center;
  justify-items: center;  
}

.page-header .header-content .title {
  font-size: 7.5rem;
  font-family: Stellina;
  font-weight: 100;
  max-width: 350px;

  color: var(--color-text-in-primary-pink-strong);
}

.page-header .header-content .subtitle {
  font-size: 1.6rem;
  color: var(--color-text-in-primary-white);
  font-family: 'Confortaa-Regular';
  padding-bottom: 20px;
  text-align: center;
  letter-spacing: 0.33em
 }
