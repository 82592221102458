.page-footer{
  display: flex;
  width: 100vw;
  height: 30rem;
  justify-content: center;
  align-items: center;
  background-color: var(--color-page-header);
  align-content: flex-end;
  flex-direction: column;
};

.page-footer h1{
  color: #AE4C60;
}

.page-footer .footerIcons-div{
  display: flex;
  height: 8rem;
  align-content: center;
  align-items: center;
  justify-items: center;
}

.page-footer .footerIcons-div .FooterIcons{
  width: 5rem;
  padding: 0.2rem;
  color: #AE4C60;
  margin-top: 10px;
  transition: heigth 0.5s;
}

.FooterIcons:hover{
  height: 3.5rem;
}

.gmail{
  width: 5rem;
  transition: width 0.2s;
  color: #AE4C60;
  margin-top: 10px;
  transition: heigth 0.5s;
}

#rights-img{
  height:22px!important;
  margin-left:3px;
  vertical-align:text-bottom;
}
#imgs-row{
  display: flex;
  flex-direction: row;
  
}
