.MainPage{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background: var(--color-main-page-ligth);
  width: 100vw;
}