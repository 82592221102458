.sec-carroucel{
  display: grid;
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: 1080;
  height: 38rem;
  margin: 0 auto;
}

.sec-carroucel .div-child-section{
  display: flexbox;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  border: aqua 1;
  width: 100vw;
  max-width: 1080px;
}

.line-break{ 
  height: 1px;
  width: 100%;
  background: var(--color-page-header);
}

h1{
  font-family: 'Confortaa-Regular';
  padding: 1rem;
  padding-top: 2rem;
  color: var(--color-text-in-carroucel);
  font-weight: 100;
  align-self: right;
}


img{
  width: 100%;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-items: center;
}