.icons-img{
  display: flex;
  height: 5rem;
  justify-content: center;
  align-items: center;
}

#photoshop-img{
  width: 3rem;
  height: 3rem;
  align-content: center;
  align-items: center;
  justify-items: center;
  padding-right: 5px;
  transition: width 0.2s;
}
#photoshop-img:hover{
  width: 3.8rem;
  height: 3.8rem;
}

#illustrator-img{
 width: 3rem;
 height: 3rem;
 padding-right: 5px;
 transition: width 0.2s;
}

#illustrator-img:hover{
  width: 3.8rem;
  height: 3.8rem;
}

#figma-img{
 width: 3rem;
 height: 3rem;
 transition: width 0.2s;
}

#figma-img:hover{
  width: 4rem;
  height: 4rem;
}
